import React from "react";
import styled from "styled-components";
import { device } from "constants/Devices";
import { dashboardLink } from "constants/Links";
import LinkButton from "components/LinkButton";
import GitHubButton from "react-github-btn";

interface IActionProps {
  handleSearch: (value: string) => void;
  handleSignIn: () => void;
  handleGetStarted: () => void;
  isMobile?: boolean;
}

const GithubBadge = () => {
  return (
    <ButtonWrapper>
      <GitHubButton
        href="https://github.com/beam-cloud/beta9"
        data-color-scheme="no-preference: dark_dimmed; light: dark_dimmed; dark: dark;"
        data-size="large"
        data-show-count="true"
        aria-label="Star beam-cloud/beta9 on GitHub"
      >
        Star
      </GitHubButton>
    </ButtonWrapper>
  );
};

const ActionComponents: React.FC<IActionProps> = ({ isMobile }) => {
  return (
    <ActionWrapper>
      {/* {!isMobile && <GithubBadge />} */}
      <LinkButton
        href={dashboardLink}
        width={isMobile ? "100%" : ""}
        buttonTheme="primary"
        height={!isMobile ? "32px" : ""}
      >
        Get Started
      </LinkButton>
    </ActionWrapper>
  );
};

export default ActionComponents;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
`;

const ButtonWrapper = styled.div`
  margin-top: 4px;
`;
