import styled, { css } from "styled-components";
import { Link, useRouteMatch } from "react-router-dom";
import NavLink from "components/NavLink";
import { Typography } from "@beamcloud/design-system";
import DesktopLogo from "assets/beam/beam-logo.svg";
import { FooterLinks } from "./data";

type TProps = {};
const Footer: React.FC<TProps> = () => {
  let match = useRouteMatch();

  return (
    <Container as="footer">
      <InnerContainer>
        <FooterContent>
          <LogoContainer>
            <LogoHeader>
              <IconLink to={`${match.url}`}>
                <Logo src={DesktopLogo} alt="slai-logo-footer" />
              </IconLink>
            </LogoHeader>
            <Typography tag="span" variant="textBase" color="gray11">
              Serverless Infrastructure for Generative AI
            </Typography>
          </LogoContainer>
          <LinkContainer>
            {FooterLinks.map(({ sectionName, sectionLinks }, key) => {
              return (
                <LinkSectionContainer key={key}>
                  <Typography tag="h4" variant="textBasePlus" color="gray11">
                    {sectionName}
                  </Typography>
                  <LinkSectionLinks>
                    {sectionLinks.map((link, key) => {
                      return (
                        <LinkSectionLinkWrapper key={key}>
                          <LinkSectionLink href={link.link}>{link.name}</LinkSectionLink>
                        </LinkSectionLinkWrapper>
                      );
                    })}
                  </LinkSectionLinks>
                </LinkSectionContainer>
              );
            })}
          </LinkContainer>
          <LegalContainer>
            <Typography tag="span" variant="textSm" color="gray11">
              © {new Date().getFullYear()} Smartshare, Inc.
            </Typography>
          </LegalContainer>
        </FooterContent>
      </InnerContainer>
    </Container>
  );
};

export default Footer;

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    isolation: isolate;
    width: 100vw;
    padding: 80px 64px 64px;
    background-color: ${theme.colors.gray1};

    @media (max-width: ${theme.breakpoints.xl}px) {
      padding: 80px 32px 64px;
    }

    @media (max-width: ${theme.breakpoints.lg}px) {
      padding: 80px 24px 64px;
    }

    @media (max-width: ${theme.breakpoints.sm}px) {
      padding: 80px 16px 64px;
    }
  `}
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  width: 100%;
  max-width: 1024px;
`;

const FooterContent = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  column-gap: 16px;
  row-gap: 48px;
  padding: 0px 32px;
  width: 100%;

  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.lg}px) {
      grid-template-columns: 1fr;
    }
  `}
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
`;

const LogoHeader = styled.h1`
  all: unset;
`;

const IconLink = styled(Link)`
  && {
    width: fit-content;
    height: fit-content;
  }
`;

const LinkContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 16px;
  row-gap: 32px;

  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.sm}px) {
      grid-template-columns: 1fr;
    }
  `}
`;

const LinkSectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
`;

const LinkSectionLinks = styled.ul`
  all: unset;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
`;

const LinkSectionLinkWrapper = styled.li`
  all: unset;
`;

const LinkSectionLink = styled(NavLink)`
  && {
    ${props => props.theme.styleSet.textBase};
    color: ${props => props.theme.colors.gray12};
    cursor: pointer;
    :hover {
      text-decoration: underline;
      color: ${props => props.theme.colors.gray12};
    }
  }
`;

const Logo = styled.img`
  height: 32px;
  width: auto;
`;

const LegalContainer = styled.aside`
  display: flex;
  margin: flex-start;
`;
