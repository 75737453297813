import { SectionContainer, SectionTitle } from "screens/Homepage/styles";
import BuildingBlock from "./BuildingBlock";
import Blocks from "./data";

interface IProps {}

const BuildingBlocks: React.FC<IProps> = () => {
  return (
    <SectionContainer>
      <SectionTitle>Your complete platform for AI/ML</SectionTitle>
      {Blocks.map((block, key) => (
        <BuildingBlock key={key} {...block} />
      ))}
    </SectionContainer>
  );
};

export default BuildingBlocks;
