import React from "react";
import styled, { css } from "styled-components";
import OnDemandDevEnvImg from "assets/homepage/landing/features/DevEnvironment.webp";
import MagicalHotReloading from "assets/homepage/landing/features/HotReloading.webp";
import { SectionContainer, SectionTitle } from "screens/Homepage/styles";
import FeatureCard from "./FeatureCard";

const FeaturesSection: React.FC = () => {
  return (
    <SectionContainer>
      <SectionTitle>The cloud environment you’ll love developing in</SectionTitle>
      <InnerContainer>
        <MainFeaturesGrid>
          <FeatureCard
            image={OnDemandDevEnvImg}
            title="Magical Hot Reloading"
            description="Run your code on any hardware, practically instantly. You only need to change one line of Python to run your app on a different GPU."
          />
          <FeatureCard
            image={MagicalHotReloading}
            title="Easy Local Debugging"
            description="We make it easy to test your code before deploying it, using the exact configuration you'll run in production."
          />
        </MainFeaturesGrid>
        <SecondaryFeaturesGrid>
          <FeatureCard
            title="Multiple Workers Per Container"
            description="Scale vertically by running multiple workers on the same container."
            docsUrl="https://docs.beam.cloud/v2/scaling/concurrency"
          />
          <FeatureCard
            title="Import Remote Dockerfiles"
            description="Bootstrap containers with images from remote registries."
            docsUrl="https://docs.beam.cloud/v2/environment/custom-images#adding-custom-base-images"
          />
          <FeatureCard
            title="Deploy from Github Actions"
            description="Deploy your APIs automatically by adding Beam to your existing CI/CD pipeline."
            docsUrl="https://docs.beam.cloud/v2/topics/ci"
          />
        </SecondaryFeaturesGrid>
      </InnerContainer>
    </SectionContainer>
  );
};

export default FeaturesSection;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 100%;
  max-width: 1152px;
`;

const MainFeaturesGrid = styled.div`
  ${({ theme }) => {
    return css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 24px;
      width: 100%;
      justify-items: center;

      @media (max-width: ${theme.breakpoints.lg}px) {
        grid-template-columns: 1fr;
      }

      div {
        width: 100%;
        height: auto;
      }
    `;
  }}
`;

const SecondaryFeaturesGrid = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-direction: row;
      gap: 24px;
      grid-gap: 24px;
      width: 100%;
      align-items: center;

      @media (max-width: ${theme.breakpoints.lg}px) {
        flex-direction: column;
      }
    `;
  }}
`;
